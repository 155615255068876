import React, { useState, useEffect } from 'react'
import Grid from '@material-ui/core/Grid'
import Container from '@material-ui/core/Container'
import TextField from '@material-ui/core/TextField'
import Destination from '../components/destinations'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import SearchIcon from '@material-ui/icons/Search'
import InputAdornment from '@material-ui/core/InputAdornment'
import Banner from './Banner'
import CssBaseline from '@material-ui/core/CssBaseline'
import Box from '@material-ui/core/Box'
import InfiniteScroll from 'react-infinite-scroll-component'



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1
    },
    alignItemsAndJustifyContent: {
        width: 500,
        height: 80,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
    },

    inputComponent: {
        borderRadius: '5px',
        backgroundColor: '#FFFFFF',
        boxShadow: '0 1px 0 0 rgba(170,170,170,0.01)'
    }
}))

const api = axios.create({
    baseURL: "https://prithvib.pythonanywhere.com/api",
    // baseURL: "http://localhost:8000/api",
    headers: {
        "Content-type": "application/json",
        "Authorization": "token 797515698641dde3b84f9296f579a7a738e813cb"
    }
})

function DestinationList() {
    const [destinations, setdestinations] = useState([])
    const [pagenumber, setpagenumber] = useState(1)
    // const [isLoading, setisLoading] = useState(true)
    const [hasmore, sethasmore] = useState(true)
    const [searchString, setsearchString] = useState('')
    const classes = useStyles()


    const getDestinations = () => {
        api.get('/simplytravel/listpage/?page=' + pagenumber).then(res => {
            setdestinations([...destinations, ...res.data.results])
            setpagenumber(pagenumber + 1)
            if (res.data.next === null) {
                sethasmore(false)
            }
        }).catch((error) => {
            console.log("error in fetching data")
            console.error(error)
        })

    }

    const searchDestination = () => {
        api.get('/simplytravel/searchpage/?search=' + searchString).then(res => {
            setdestinations([...res.data.results])
            // setisLoading(false)
        }).catch((error) => {
            console.log("error in fetching data")
            console.error(error)
        })
    }

    const onSearchInputChange = (event) => {
        console.log("Search changed ..." + event.target.value)
        if (event.target.value) {
            setsearchString(event.target.value)
            // setisLoading(true)
        } else {
            setsearchString({ searchString: '' })
        }
        searchDestination()
    }

    useEffect(() => {
        getDestinations()// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (

        <div className={classes.root}>
            <CssBaseline />
            <Box>
                <Container maxWidth='md'>
                    {/* <Banner/> */}

                    <Grid container justify='center'>
                        <Banner />
                        <TextField
                            id="searchInput"
                            placeholder="Search for destination"
                            margin="normal"
                            variant="outlined"
                            onChange={onSearchInputChange}
                            className={classes.inputComponent}
                            fullWidth
                            InputProps={{
                                startAdornment: (
                                    <InputAdornment position='start'>
                                        <SearchIcon />
                                    </InputAdornment>
                                )
                            }}
                        />
                    </Grid>

                    <InfiniteScroll
                        dataLength={destinations.length}
                        next={getDestinations}
                        hasMore={hasmore}
                        loader={'Loading. . . '}
                        endMessage={
                            <p style={{ textAlign: 'center' }}>
                                <b>The list is growing, more places will be added as we Drive Trek and Explore :)</b>
                            </p>
                        }>

                        <Grid container spacing={3} style={{ padding: 24 }}>
                            {destinations.map(alldestinations => (
                                <Grid item xs={12} sm={6} lg={4} xl={2}>
                                    <Destination destination={alldestinations} />
                                </Grid>
                            ))}
                            {/* {isLoading && 'Searching ...'} */}
                        </Grid>
                    </InfiniteScroll>
                </Container>
            </Box>
        </div>

    )
}

export default DestinationList