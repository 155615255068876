import React, {useState} from 'react';
import clsx from 'clsx'
import { makeStyles, useTheme } from '@material-ui/core/styles'
import {Drawer, List} from '@material-ui/core'
import CssBaseline from '@material-ui/core/CssBaseline'
import IconButton from '@material-ui/core/IconButton'
import DestinationList from './DestinationList'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import ChevronRightIcon from '@material-ui/icons/ChevronRight'
import Divider from '@material-ui/core/Divider'
import {ListItem, ListItemIcon, ListItemText} from '@material-ui/core'
import HomeIcon from '@material-ui/icons/Home'
import InfoIcon from '@material-ui/icons/Info'
import {BrowserRouter as Router, Switch, Route, Link} from 'react-router-dom'
import DestinationDetail from './DestinationDetail'
import About from './About';



const drawerWidth = 220

const useStyles = makeStyles((theme) => ({
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  expand: {
    transform: 'rotate(180deg)'
  },
  hide: {
    display: 'none',
  },
  drawerPaper: {
    width: 'inherit'
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
  },
  drawerOpen: {
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerClose: {
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.entering,
    }),
    overflowX: 'hidden',
    width: theme.spacing(7) + 1,
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9) + 1,
    },
  },
  toolbar: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: theme.spacing(0, 1),
    // necessary for content to be below app bar
    ...theme.mixins.toolbar,
  },
  content: {
    flexGrow: 1,
    padding: theme.spacing(3),
  },
  link:{
    textDecoration: 'none',
    color: theme.palette.text.primary
  },
}));

const Navbar = () => {
  const classes = useStyles();
  const theme = useTheme();
  const [open, setOpen] = useState(false)

  const handleDrawerOpen =() => {
    setOpen(!open)
  }


  return (
    <Router>
    <div style={{display:'flex'}}>
      <CssBaseline/>
      <Drawer
        variant="permanent"
        className={clsx(classes.drawer, {
          [classes.drawerOpen]: open,
          [classes.drawerClose]: !open,
        })}
        classes={{
          paper: clsx({
            [classes.drawerOpen]: open,
            [classes.drawerClose]: !open,
          }),
        }}
      >
        <div className={classes.toolbar}>
          <IconButton onClick={handleDrawerOpen}
          className={classes.expand}>
            {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </IconButton>
        </div>
        <Divider />
        <List>
          <Link to="/" className={classes.link}>
            <ListItem button key='Home'>
              <ListItemIcon><HomeIcon /> </ListItemIcon>
              <ListItemText primary='Home' />
            </ListItem>
            </Link>
            <Link to="/about" className={classes.link}>
              <ListItem button key='About'>
                <ListItemIcon><InfoIcon /></ListItemIcon>
                <ListItemText primary='About' />
              </ListItem>
            </Link>
            {/* <Link to="/contact" className={classes.link}>
              <ListItem button key='Contact'>
                <ListItemIcon><ContactPhoneIcon /></ListItemIcon>
                <ListItemText primary='Contact' />
              </ListItem>
            </Link> */}
        </List>
      </Drawer>
      <Switch>
        <Route exact path="/" component={DestinationList}/>
        <Route exact path="/about" component={About}/>
        {/* <Route exact path="/contact">
          Contact
        </Route> */}
        <Route path="/destination/:id" component={DestinationDetail}/>
      </Switch>
    </div>
    </Router>
  );
}

export default Navbar;
