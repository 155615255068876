
import React from 'react';
import {CardMedia} from '@material-ui/core'
import Swiper from 'react-id-swiper';
import 'swiper/css/swiper.css'
import { makeStyles } from '@material-ui/core/styles'
import image1 from './banner/DSC_3889.JPG'
import image2 from './banner/IMG_20160618_145734.jpg'
import image3 from './banner/IMG_20161108_152807_HDR.jpg'  
import image4 from './banner/IMG_20170812_151958.jpg'
import image5 from './banner/DSC_8146.JPG'  
import image6 from './banner/IMG_20161106_133820_HDR.jpg'  
import image7 from './banner/IMG_20170409_170757.jpg'      
import image8 from './banner/IMG_20171125_062632.jpg'


const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
    },
    paper: {
        padding: theme.spacing(2),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        display: 'flex'
    },
    card:{
        maxWidth:500,
    },
}))
const Banner = () => {
    const params = {
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        slidesPerView: 'auto'
    }

    const classes = useStyles()



    return (
        <Swiper {...params}>
            <div className={classes.root}>
                <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image1}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image2}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image3}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image4}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image5}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image6}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image7}
                        title=""
                    />
                </card>
            </div>
            <div>
            <card className={classes.card}>
                    <CardMedia
                        component="img"
                        alt=""
                        height="300"
                        image={image8}
                        title=""
                    />
                </card>
            </div>
        </Swiper>
    )
};

export default Banner;
