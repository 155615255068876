import React, { Component } from 'react';
import  Navbar  from './components/Navbar'
// import DestinationList from './components/DestinationList'
// import axios from 'axios'
import Titlebar from './components/Titlebar'
// import {Paper, Divider} from '@material-ui/core'


// const api = axios.create({
//   baseURL: "http://localhost:8000/api/simplytravel",
//   headers: {
//     "Content-type": "application/json"
//   }
// })

class App extends Component {

  state = {
    destinations: []
  }

  // constructor() {
  //   super()
  //   api.get('').then(res =>{
  //     console.log(res.data)
  //     this.setState({ destinations : res.data})
  //   })
  // }

render() {
  return (
    <div className="App">
      {/* <header className="App-header"> */}
      <Titlebar/>
      <Navbar/>
      {/* <DestinationList/> */}
      {/* </header> */}
    </div>
  );
}
}

export default App;
