import React, { useState } from 'react'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import {
    Avatar, ListItemIcon,
    ListItemText
} from '@material-ui/core'
import CardHeader from '@material-ui/core/CardHeader'
import IconButton from '@material-ui/core/IconButton'
// eslint-disable-next-line
import ShareIcon from '@material-ui/icons/Share'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import { brown, lightGreen } from '@material-ui/core/colors'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import List from '@material-ui/core/List'
import Collapse from '@material-ui/core/Collapse'
import ListItem from '@material-ui/core/ListItem'
import { Link } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGopuram, faUmbrellaBeach, faDove, faArchway, faMountain, faPallet, faWater, faTree, faChartArea, faRoad, faCloudSun, faCoffee } from '@fortawesome/free-solid-svg-icons'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'


const useStyles = makeStyles((theme) => ({
    media: {
        height: 0,
        paddingTop: '56.25%', // 16:9
    },
    expand: {
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        transform: 'rotate(180deg)',
    },
    avatar: {
        backgroundColor: brown[500],
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
}));


function Destinations(props) {
    console.log(props)
    const classes = useStyles()

    const [expanded, setExpanded] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }

    // Caves
    // Historical
    // Water fall

    const handleIcon = () => {
        if (props.destination.place_category === 'Pilgrim' || props.destination.place_category === 'Piligrim' || props.destination.place_category === 'Temple Paintings') {
            return (<FontAwesomeIcon icon={faGopuram} />)
        }
        else if (props.destination.place_category === 'Archeology') {
            return (<FontAwesomeIcon icon={faArchway} />)
        }
        else if (props.destination.place_category === 'Beach') {
            return (<FontAwesomeIcon icon={faUmbrellaBeach} />)
        }
        else if (props.destination.place_category === 'Birds sanctuary') {
            return (<FontAwesomeIcon icon={faDove} />)
        }
        else if (props.destination.place_category === 'Hill' || 'Hill Station' || 'Hill station') {
            return (<FontAwesomeIcon icon={faMountain} />)
        }
        else if (props.destination.place_category === 'Landscape') {
            return (<FontAwesomeIcon icon={faChartArea} />)
        }
        else if (props.destination.place_category === 'River' || 'Ravine') {
            return (<FontAwesomeIcon icon={faWater} />)
        }
        else if (props.destination.place_category === 'Wildlife') {
            return (<FontAwesomeIcon icon={faTree} />)
        }
        else if (props.destination.place_category === 'Barrage' || 'Dam') {
            return (<FontAwesomeIcon icon={faPallet} />)
        }
        else return (props.destination.place_category.charAt(0).toUpperCase())
    }

    return (
        <div>
            <Card >
                <Link to={`/destination/${props.destination.id}`} className={classes.link}>
                    <CardHeader
                        avatar={
                            <Avatar arial-lable="recipe" style={{ backgroundColor: lightGreen[800] }}>
                                {handleIcon()}
                            </Avatar>
                        }
                        title={props.destination.destination} />
                    <CardMedia style={{ height: 0, paddingTop: '56.25%' }}
                        image={props.destination.image_url}
                        title={props.destination.destination}
                    />
                    <CardContent>
                        <Typography gutterBottom variant="headline" component="h2">
                            {props.destination.destination}
                        </Typography>
                        <Typography component="p">
                            {props.destination.city}
                        </Typography>
                    </CardContent>
                </Link>
                <CardActions disableSpacing>
                    {/* <IconButton aria-label="share">
                        <ShareIcon/>
                    </IconButton> */}

                    <IconButton
                        className={clsx(classes.expand, {
                            [classes.expandOpen]: expanded,
                        })}
                        onClick={handleExpandClick}
                        area-expanded={expanded}
                        area-lable="show more"
                    >
                        <ExpandMoreIcon />
                    </IconButton>

                </CardActions>
                <Collapse in={expanded} timeout="auto" unmountOnExit>
                    <CardContent>
                        <List>
                            <ListItem key='Fuel' alignItems='flex-start'>
                                <ListItemIcon><LocalGasStationIcon style={{ fontSize: 40 }} /></ListItemIcon>
                                <ListItemText secondary={props.destination.fuel_stations} primary='Nearest Fuel' className={classes.list} />
                            </ListItem>
                            <ListItem key='Road' alignItems='flex-start'>
                                <ListItemIcon><FontAwesomeIcon icon={faRoad} style={{ fontSize: 40 }} /></ListItemIcon>
                                <ListItemText secondary={props.destination.road_condition} primary='Road Condition' className={classes.list} />
                            </ListItem>
                            <ListItem key='BestTime' alignItems='flex-start'>
                                <ListItemIcon><FontAwesomeIcon icon={faCloudSun} style={{ fontSize: 40 }} /></ListItemIcon>
                                <ListItemText secondary={props.destination.visiting_from + '-' + props.destination.visiting_to} primary='Visiting Time' className={classes.list} />
                            </ListItem>
                            <ListItem key='Food' alignItems='flex-start'>
                                <ListItemIcon><RestaurantMenuIcon style={{ fontSize: 40 }} /></ListItemIcon>
                                <ListItemText secondary={props.destination.food_destination} primary='Food at Destination' className={classes.list} />
                            </ListItem>
                            <ListItem key='FoodOnRoute' alignItems='flex-start'>
                                <ListItemIcon><FontAwesomeIcon icon={faCoffee} style={{ fontSize: 40 }} /></ListItemIcon>
                                <ListItemText secondary={props.destination.food_enroute} primary='Food Enroute' className={classes.list} />
                            </ListItem>
                        </List>
                    </CardContent>
                </Collapse>
            </Card>
        </div>
    )
}

export default Destinations