import React from 'react'
import { makeStyles } from '@material-ui/core/styles'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import Typography from '@material-ui/core/Typography'
import { Grid, Container } from '@material-ui/core'
import Box from '@material-ui/core/Box'
import './style.css'

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    maxWidth: 500,
    background: 'linear-gradient(45deg, #2196F3 30%, #21CBF3 90%)',
    color: 'white',
    boxShadow: '0 3px 5px 2px rgba(33, 203, 243, .3)'
  },
  card: {
    maxWidth: 500,

  },
  details: {
    display: 'flex',
    flexDirection: 'column',
  },
  media: {
    disply: 'flex',
    flexDirection: 'row',
  },
  content: {
    display: 'flex',
    flex: '1 0 auto',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    paddingTop: '0.5',
  },
  cover: {
    width: 150,
    height: 150
  },
  controls: {
    height: '100%',
  },
  alignItemsAndJustifyContent: {
    width: 500,
    height: 80,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
    justifyContent: 'center',
    alignItems: 'center'
  },
}));

const Titlebar = () => {
  const classes = useStyles()
  const bull = <span className={classes.bullet}>•</span>;

  return (
    <Box ml={10}>
      <Container maxWidth="md">
        <Grid container justify='center'>
          <div className={classes.details}>
            <CardContent className={classes.content} >
              <Typography component="h5" variant="h5" style={{ fontFamily: "Rock Salt", color: 'black' }}>
                THE SIMPLE TRAVELER
            </Typography>
            </CardContent>
          </div>
          <div className={classes.media}>
            <CardMedia
              className={classes.cover}
              image="../logo.png"
              title="Simpletraveler"
              justify='center'
            />
          </div>
          <div className={classes.details}>
            <CardContent className={classes.content}>
              <Typography component="h5" variant="h5" style={{ fontFamily: "Boogaloo", color: 'black' }} >
                Drive{bull}Trek{bull}Explore
            </Typography>
            </CardContent>
          </div>
        </Grid>
      </Container>
    </Box>
  )
}

export default Titlebar