import React, { useState, useEffect } from 'react'
import axios from 'axios'
import { makeStyles } from '@material-ui/core/styles'
import {
    Card,
    CardMedia,
    Container,
    Box,
    Typography,
    CardContent,
    ListItem,
    ListItemIcon,
    ListItemText,
    List,
    CardHeader
} from '@material-ui/core'
import LocalGasStationIcon from '@material-ui/icons/LocalGasStation'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCoffee, faRoad, faCloudSun } from '@fortawesome/free-solid-svg-icons'
import RestaurantMenuIcon from '@material-ui/icons/RestaurantMenu'
import MapRender from './MapRender'
import './style.css'
import parse from 'html-react-parser'
import { cyan } from '@material-ui/core/colors'



const useStyles = makeStyles((theme) => ({
    root: {
        flexGrow: 1,
        zIndex: theme.zIndex.drawer + 1,
        '&:last-child': {
            paddingBottom: 24,
        },
    },
    chips: {
        display: 'flex',
        flexDirection: 'row',
    },
    list: {
        width: '100%',
        maxWidth: 300,
        textTransform: 'capitalize',
    },
    map: {
        margin: '10px'
    },
    cards: {
        padding: 16,
        // backgroundColor: '#FFFAEF',
        '&:last-child': {
            paddingBottom: 24,
        },
    },
}))

const api = axios.create({
    baseURL: "https://prithvib.pythonanywhere.com/api",
    // baseURL: "http://localhost:8000/api",
    headers: {
        "Content-type": "application/json",
        "Authorization": "token 797515698641dde3b84f9296f579a7a738e813cb"
    }
})

function DestinationDetail({ match }) {
    const [destination, setdestination] = useState({})
    const [summary, setsummary] = useState('')
    const [isLoading, setisLoading] = useState(true)

    const classes = useStyles()
    // const theme = useTheme();
    console.log(match)

    const getDestination = async () => {
        try {
            const res = await api.get(`/simplytravel/${match.params.id}`)
            setdestination(res.data)
            setsummary(res.data.description)
            console.log(res.data)
            // const summary_res = await api.get('/simplytravel/search/' + res.data.destination.replace(/\s+/g, '-').toLowerCase())
            // setsummary(summary_res.data)
            setisLoading(false)
            // console.log("i am here " + summary_res.data)
        } catch (e) {
            console.log("error fetching data")
            console.error(e)
        }
    }


    useEffect(() => {
        getDestination()
        window.scrollTo(0, 0)// eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])



    return (
        <div className={classes.root}>
            <Container maxWidth='md'>
                <Card justifyContent='center' className={classes.cards}>
                    <Box ml={5}>
                        <CardHeader title={<Typography style={{ fontSize: 30, textTransform: 'uppercase', fontFamily: "Boogaloo", color: 'black' }}>{destination.destination}</Typography>} />
                    </Box>
                    <Box height='75%' width='75%' justifyContent='center' display='flex' alignItems='center' ml={15}>
                        <CardMedia
                            component="img"
                            // className={classes.media}
                            alt={destination.destination}
                            image={destination.image_url}
                            // title={<Typography varient='headline' component='h2' style={{ fontSize: 20, fontFamily: "Rock Salt", color: 'black' }}>Test</Typography>}
                            title={destination.destination}
                        />
                        <div className={classes.chips}>
                            {/* <Chip color="primary" icon={<LocalGasStationIcon />} label= {destination.fuel_stations}/>  */}
                            <List className={classes.list}>
                                <ListItem key='Fuel' alignItems='flex-start'>
                                    <ListItemIcon><LocalGasStationIcon style={{ fontSize: 40 }} /></ListItemIcon>
                                    <ListItemText secondary={destination.fuel_stations} primary='Nearest Fuel' className={classes.list} />
                                </ListItem>
                                <ListItem key='Road' alignItems='flex-start'>
                                    <ListItemIcon><FontAwesomeIcon icon={faRoad} style={{ fontSize: 40 }} /></ListItemIcon>
                                    <ListItemText secondary={destination.road_condition} primary='Road Condition' className={classes.list} />
                                </ListItem>
                                <ListItem key='BestTime' alignItems='flex-start'>
                                    <ListItemIcon><FontAwesomeIcon icon={faCloudSun} style={{ fontSize: 40 }} /></ListItemIcon>
                                    <ListItemText secondary={destination.visiting_from + '-' + destination.visiting_to} primary='Visiting Time' className={classes.list} />
                                </ListItem>
                                <ListItem key='Food' alignItems='flex-start'>
                                    <ListItemIcon><RestaurantMenuIcon style={{ fontSize: 40 }} /></ListItemIcon>
                                    <ListItemText secondary={destination.food_destination} primary='Food at Destination' className={classes.list} />
                                </ListItem>
                                <ListItem key='FoodOnRoute' alignItems='flex-start'>
                                    <ListItemIcon><FontAwesomeIcon icon={faCoffee} style={{ fontSize: 40 }} /></ListItemIcon>
                                    <ListItemText secondary={destination.food_enroute} primary='Food Enroute' className={classes.list} />
                                </ListItem>
                            </List>
                        </div>
                    </Box>

                    <CardContent>

                        <Typography variant="body1" color="textSecondary" component="p" style={{fontSize:18, fontFamily: "Crimson Text"}}>
                            {destination.short_description} {isLoading && 'loading . . .'}
                        </Typography>
                        {/* <div>{parse(destination.description)}</div> */}
                        <Typography varient ="body2" color="textSecondary" component="p" style={{fontSize:18, fontFamily: "Crimson Text"}}>
                        {parse(summary)}
                        </Typography>
                    </CardContent>
                    <CardContent>
                        <MapRender id={match.params.id} />
                    </CardContent>
                </Card>
            </Container>
        </div>

    )
}

export default DestinationDetail
