import React, { useState } from 'react'
import { makeStyles } from '@material-ui/core/styles'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import CardMedia from '@material-ui/core/CardMedia'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import Container from '@material-ui/core/Container'
import CardActions from '@material-ui/core/CardActions'
import ExpandMoreIcon from '@material-ui/icons/ExpandMore'
import CardHeader from '@material-ui/core/CardHeader'
import clsx from 'clsx'
import Collapse from '@material-ui/core/Collapse'
import image from '../IMG_20170409_171022.jpg'
import FacebookIcon from '@material-ui/icons/Facebook'
import Grid from '@material-ui/core/Grid'
import InstagramIcon from '@material-ui/icons/Instagram'
import EmailIcon from '@material-ui/icons/Email'
import './style.css'


const useStyles = makeStyles((theme) => ({
    root: {
        display: 'flex',
    },
    details: {
        display: 'flex',
        flexDirection: 'column',
    },
    content: {
        flex: '1 0 auto',
    },
    cover: {
        width: 100,
    },
    controls: {
        display: 'flex',
        alignItems: 'center',
        paddingLeft: theme.spacing(1),
        paddingBottom: theme.spacing(1),
    },
    playIcon: {
        height: 38,
        width: 38,
    },
    link: {
        textDecoration: 'none',
        color: theme.palette.text.primary
    },
    cards: {
        padding: 16,
        '&:last-child': {
            paddingBottom: 24,
        },
    },
}));

function About() {
    const classes = useStyles();
    // const theme = useTheme();
    const [expanded, setExpanded] = useState(false)
    const handleExpandClick = () => {
        setExpanded(!expanded)
    }
    return (
            <Container maxWidth='md'>
                <Card justifyContent='center' className={classes.cards} >
                    <CardHeader
                        title={<Typography varient='headline' component='h2' style={{ fontSize: 20, fontFamily: "Rock Salt", color: 'black' }}>Simple Traveler</Typography>}
                    />
                    <CardMedia style={{ height: 0, paddingTop: '56.25%' }}
                        image={image}
                        title='prithvi'
                    />
                    <CardContent>
                        <Typography gutterBottom variant="headline" component="h2">
                            Prithvi Bharadwaj
                    </Typography>
                        <Typography component="p">
                            Love for coding and traveling has made me build this website.If you are travel freak like me and find it difficult to find new places around Bangalore,
                            this website might help you guys navigate and explore new and unexplored destinations.

                    </Typography>
                        <Typography component="p">
                            The contents of this website are from different sources - mostly personal with a little help from Google/Wikipedia for the stats.
                    </Typography>
                    </CardContent>
                    <CardActions disableSpacing>
                        <IconButton
                            className={clsx(classes.expand, {
                                [classes.expandOpen]: expanded,
                            })}
                            onClick={handleExpandClick}
                            area-expanded={expanded}
                            area-lable="show more"
                        >
                            <ExpandMoreIcon />
                        </IconButton>
                    </CardActions>
                    <Collapse in={expanded} timeout="auto" unmountOnExit>
                        <CardContent>
                            <Typography gutterBottom variant="headline" component="h2">
                                Contact:
                    </Typography>
                            <Grid container spacing={2}>
                                <Grid item >
                                    <a href="https://www.facebook.com/bharadwaj.prithvi" target='_blank' className={classes.link} rel='noopener noreferrer'>
                                        <FacebookIcon />
                                    </a>
                                </Grid>
                                <Grid item >
                                    <a href="https://www.instagram.com/prithvibharadwaj/" target='_blank' className={classes.link} rel='noopener noreferrer'>
                                        <InstagramIcon />
                                    </a>
                                </Grid>
                                <Grid item>
                                    <a href="mailto:prithvi.bharadwaj@gmail.com" className={classes.link}>
                                        <EmailIcon />
                                    </a>
                                </Grid>
                            </Grid>
                        </CardContent>
                    </Collapse>
                </Card>
            </Container>
    )
}

export default About
